import React from 'react';
import './WalletMenu.css';

const WalletMenu = ({ setWalletAPI, connectedWalletAPI,setWalletName,Cookies,onClose}) => {
  let walletAPI,myOrders;
  const isNamiAvailable = window.cardano?.nami !== undefined;
  const isEternlAvailable = window.cardano?.eternl !== undefined;
  const isFlintAvailable = window.cardano?.flint !== undefined;



  return (
    <div className="backdrop" onClick={onClose}>
    <div className="wallet-menu">
      <h2 style={{textAlign:"center"}}>Connect Wallet</h2>
      <ul className="wallet-list">
        {isNamiAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.nami.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Nami');Cookies.set('lastConnectedWalletName','nami',{expires:1000})}}>
                <img src={window.cardano.nami.icon} alt="Nami Icon" className="wallet-icon" />
                Nami
            </button>
          </li>
        )}
        {isEternlAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.eternl.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Eternl');Cookies.set('lastConnectedWalletName','eternl',{expires:1000})}}>
                <img src={window.cardano.eternl.icon} alt="Eternl Icon" className="wallet-icon" />
                Eternl
            </button>
          </li>
        )}
        {isFlintAvailable && (
          <li className="wallet-item">
            <button onClick={async () => {walletAPI = await window.cardano.flint.enable();setWalletAPI(walletAPI);connectedWalletAPI=walletAPI;setWalletName('Flint');Cookies.set('lastConnectedWalletName','flint',{expires:1000})}}>
                <img src={window.cardano.flint.icon} alt="Flint Icon" className="wallet-icon" />
                Flint
            </button>
          </li>
        )}
      </ul>
      {(!isNamiAvailable & !isEternlAvailable & !isFlintAvailable)?
      <>
      <div>
      This browser does not have Nami, Eternl or Flint wallets installed.
      </div>
      <br/>
      <div>
        You can mint manually by sending ADA directly to script:
      </div>
      <br/>
      <ul className="wallet-list">
        <li className="wallet-item">
            <button onClick={() => {
              Date.now()/1000<1709211600?
              navigator.clipboard.writeText("addr1v9dmzv2s9v8kcv8dpaz95cx9t9m2y9tuxh0rtum7deqx6eg9xn0c6")
              :
              navigator.clipboard.writeText("addr1vyvuad7ud2ea6ltlfn8ps3ed8823yl90zlkn2nzkeutfmdcg40ydj")
              window.alert("address copied!")
              }}>
                Copy address to clipboard
            </button>
        </li>
      </ul>
      </>
      :
      <>
      </>
      }
    </div>
    </div>
  );
};

export default WalletMenu;
