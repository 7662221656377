// src/components/Header.js
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import WalletMenu from './WalletMenu'; 
import logo from '../assets/images/adalinklogo.png';

import './Header.css'




function Header({walletAPI,setWalletAPI,connectedWalletAPI,walletName,setWalletName,orders,setMyOrders,initalizeMyOrders,Cookies}) {


  const [isWalletMenuOpen, setWalletMenuOpen] = useState(false);

  const handleOpenWalletMenu = () => {
    setWalletMenuOpen(true);
  };
  
  const handleCloseWalletMenu = () => {
    setWalletMenuOpen(false);
  };


  /*
            <li className="nav-item">
            <Link to="/revenue-share-offering">Revenue Share Offering</Link>
          </li>
  */

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={logo} width={144} alt="Your Logo" />
        </Link>
      </div>
      <nav className="nav">
        <ul className="nav-list">
          <li className="nav-item">
            <Link to="https://www.frenchies.club/" target="_blank">Home</Link>
          </li>
          <li className="nav-item">
            <Link to="https://www.frenchies.club/about" target="_blank">Team</Link>
          </li>
          <li className="nav-item">
            <Link to="https://adalink.gitbook.io/adalink/the-roadmap/frenchies-roadmap" target="_blank">Roadmap</Link>
          </li>
        </ul>
      </nav>
      <div className="wallet-connect">
        {walletAPI ? (
           <button onClick={handleOpenWalletMenu}>Connected: {walletName}</button>
        ) : (
          <button onClick={handleOpenWalletMenu}>Connect Wallet</button>
        )}
      </div>
      { isWalletMenuOpen && <WalletMenu setWalletAPI={setWalletAPI} connectedWalletAPI={connectedWalletAPI} setWalletName={setWalletName} orders={orders} setMyOrders={setMyOrders} initalizeMyOrders={initalizeMyOrders} Cookies={Cookies} onClose={handleCloseWalletMenu}/> }
    </header>
  );
}

export default Header;
