// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import './Home.css'; // Import the CSS file

import {FEE_ADDRESS,PUBLIC_FEE_ADDRESS,NFT_PRICE,WL_END_TIME_POSIX,WL_COLLECTOR_ADDRESS} from '../Constants.js';
import twoCards from "../assets/images/2-Cards.png";





function Home({lucid,walletAPI,setMessageWindowContent,setMessageWindowButtonText,setShowMessageWindow}) {

  const [wlRemainingTime,setWLRemainingTime] = useState("0");
  const [adaPaymentAmount,setAdaPaymentAmount] = useState("50");
  const [numberOfNFTs,setNumberOfNFTs] = useState("1");
  const [mintButtonText,setMintButtonText] = useState("Mint");


  useEffect(() => {
    // Your code here

    const intervalId = setInterval(() => {
      // This code will run every 5 seconds
      setWLRemainingTime(countdownTimer(WL_END_TIME_POSIX));
      let posixTimeInSeconds = Date.now()/1000;  
      let endAt = parseInt(WL_END_TIME_POSIX);
      if (endAt - posixTimeInSeconds > 86400)
        setMintButtonText("Check wallet for WL");
      else
        setMintButtonText("Mint");
    }, 1000);

    // Don't forget to clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array to run the effect only once

  const buildOrderHandler = async () => {

    setMessageWindowContent('Checking...');
    setMessageWindowButtonText('');
    setShowMessageWindow(true);
    
    if(walletAPI == undefined){
      setMessageWindowContent('Connect your wallet first');
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
      return;
    }

    if(await walletAPI.getNetworkId() != 1){
      setMessageWindowContent('Make sure to set your wallet on Mainnet');
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
      return;
    }


    

    lucid.selectWallet(walletAPI);
    let stakeAddress = await lucid.wallet.rewardAddress()
    let tx = lucid.newTx();
    
    let posixTimeInSeconds = Date.now()/1000;
    let endAt = parseInt(WL_END_TIME_POSIX);
    if(posixTimeInSeconds >= endAt){

      let response = await fetch("https://shroomsv2.adalink.io/api/get-engine-status.php?atmName=FFC",{cache:"reload"});
      let isEngineRunning = await response.text();
  
      if (isEngineRunning != "on"){
        setMessageWindowContent('Collection sold out! Thank you!');
        setMessageWindowButtonText('Ok');
        setShowMessageWindow(true);
        return;
      }
      setMessageWindowContent('Building Transaction...');
      setMessageWindowButtonText('');
      tx.payToAddress(PUBLIC_FEE_ADDRESS,{lovelace:adaPaymentAmount+"000000"});

    }else if (endAt - posixTimeInSeconds >= 86400){
      let response = await fetch("https://shroomsv2.adalink.io/api/is-wallet-whitelisted-for-project.php?table=FFCWL&address="+stakeAddress,{cache:"reload"});
      let walletStatus = await response.text();
      switch(walletStatus){
        case "-1":
          setMessageWindowContent("This wallet is not whitelisted.");
          setMessageWindowButtonText('Ok');
          setShowMessageWindow(true);
        break;
        default:
          setMessageWindowContent("This wallet can mint up to "+walletStatus+" NFT(s) during WL mint.");
          setMessageWindowButtonText('Ok');
          setShowMessageWindow(true);
        break;
      }

      return;
    }else{
      let response = await fetch("https://shroomsv2.adalink.io/api/is-wallet-whitelisted-for-project.php?table=FFCWL&address="+stakeAddress,{cache:"reload"});
      let walletStatus = await response.text();
      switch(walletStatus){
        case "-1":
          setMessageWindowContent('This wallet is not whitelisted.');
          setMessageWindowButtonText('Ok');
          setShowMessageWindow(true);
          return;
        case "0":
          setMessageWindowContent('This wallet already minted its WL NFT(s).');
          setMessageWindowButtonText('Ok');
          setShowMessageWindow(true);
          return;
        default:
          setMessageWindowContent('Building Transaction...');
          setMessageWindowButtonText('');
          tx.payToAddress(FEE_ADDRESS,{lovelace:NFT_PRICE});
          //let wlTokenSigniture = "cb174fe26ac75587d6e4f32fd0a2614672beaee85aabc9c5476edd7d4672656e6368696520466f756e64657273204275726e20546f6b656e";
          //tx.payToAddress(WL_COLLECTOR_ADDRESS,{lovelace:1000000n,[wlTokenSigniture]:1n});
        break;
      }
    }


    try{
      tx = await tx.complete();
    }
    catch(e){
      if (e == "InputsExhaustedError"){
        if(posixTimeInSeconds >= endAt)
          setMessageWindowContent("Wallet does not have enough ADA.");
        else
          setMessageWindowContent("Wallet does not have enough ADA.");
        setMessageWindowButtonText('Ok');
        setShowMessageWindow(true);
        return;
      }
    }
    try{
      const signedTx = await tx.sign().complete();
      const txHash = await signedTx.submit();
      //console.log(txHash);
      setMessageWindowContent(<><div>Transaction has been submitted to chain.</div><div style={{fontSize:"x-small",marginTop:"5px"}}>Tx Hash: {txHash}</div></>);
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
    }catch{
      setMessageWindowContent("User declined transaction.");
      setMessageWindowButtonText('Ok');
      setShowMessageWindow(true);
    }
  }

  function countdownTimer(endAtPosix){
    let posixTimeInSeconds = Date.now()/1000;
    let endAt = parseInt(endAtPosix);
    if(posixTimeInSeconds >= endAt)
        return 0;
    let secondsToEnd = endAt - posixTimeInSeconds;

    if (secondsToEnd>86400)
      secondsToEnd-=86400;

    let days = (secondsToEnd/86400);
    let hours = (secondsToEnd%86400/3600);
    let minutes = (secondsToEnd%86400%3600/60);
    let seconds = (secondsToEnd%86400%3600%60);
    
    days = String(Math.floor(days)).padStart(2,'0');
    hours = String(Math.floor(hours)).padStart(2,'0');
    minutes = String(Math.floor(minutes)).padStart(2,'0');
    seconds = String(Math.floor(seconds)).padStart(2,'0');
    return days+":"+hours+":"+minutes+":"+seconds+"";
}
  /* just in case 2cards get fucked
    <span style={{display:"inline-block",height:"100%",verticalAlign:"middle"}}></span>
  */
  return (
    <div className="home">
      <div className="">
        <div className='main-box'>
          <div className='mint-text-box'>
            <h1 style={{textAlign:"left"}}>Frenchie Founders NFT</h1>
            <p>
            An exclusive collection of 500 NFTs on the
            Cardano blockchain. This limited series features
            two distinct designs and opens with a 24-hour
            exclusive window for whitelisted supporters,
            followed by a public sale.
            </p>
            <p>
            As a Frenchie Founder, you gain exclusive access
            to workshops, sponsorships, and monetization
            opportunities. Dive into a dynamic ecosystem
            where your creativity isn't just appreciated—it's
            the driving force behind the adoption of Cardano.
            </p>
          </div>
          <div className='mint-right-side'>
            <div className='mint-img-box'>
              
              <img src={"https://adalink.io/images/2-Cards.png"} />
            </div>
            <div className='mint-box'>
              <div style={{fontSize:24}}>Frenchie Founders by AdaLink</div>
              <div className='mint-info'>
                <div>
                  <div>Price</div>
                  {
                    wlRemainingTime=="0"?
                    <>
                    <div>{adaPaymentAmount} ADA</div>
                    </>
                    :
                    <>
                    <div>50 ADA</div>
                    </>
                  }
                </div>
                <div>
                  {wlRemainingTime=="0"?
                  <>
                  <div>Select number of NFTs</div>
                  <select className="nftAmountSelector" name="nftAmountSelector" id="nftAmountSelectorDropMenu"
                    onClick={() => {let selectedRange = document.getElementById("nftAmountSelectorDropMenu").value;
                    setAdaPaymentAmount(selectedRange);
                    setNumberOfNFTs(document.getElementById("nftAmountSelectorDropMenu").selectedIndex+1)
                    }}  
                  >
                    <option value="50">1</option>
                    <option value="100">2</option>
                    <option value="150">3</option>
                  </select>
                  </>
                  :
                  <>
                  <div>
                    {WL_END_TIME_POSIX - Date.now()/1000 > 86400?
                    <>
                    WL Mint Opens in
                    </>
                    :
                    <>
                    WL Mint Remaining Time
                    </>
                    }
                    
                  </div>
                  <div>{wlRemainingTime}</div>
                  </>
                  }
                </div>
              </div>
              <button className='mint-button' onClick={async () => {await buildOrderHandler()}}>{mintButtonText}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
